<template>
  <v-card v-if="value" class="mx-auto card">
    <v-card-title
      :style="`background-color:${getNitrateColor(value.nitrate)}`"
      class="card-title"
    >
      <v-spacer></v-spacer>
      <span v-if="value.nitrate >= 2" class="mr-2 alert-icon">
        <v-icon v-if="value.nitrate < 5" color="red">
          mdi-alert-outline
        </v-icon>
        <v-icon v-else color="yellow"> mdi-alert-octagon-outline </v-icon>
      </span>
      <span class="card-title--text">
        <slot name="testResultPrefix"> My Water </slot> Test =
        {{ value.nitrate }} mg/L Nitrates
      </span>
      <v-spacer></v-spacer>
      <slot name="close">
        <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </slot>
    </v-card-title>
    <v-card-text class="card-text pa-6 text-center">
      <p class="mb-8 card-value">
        Higher levels of nitrate and corresponding carcinogenic toxins in well
        water have been scientificaly shown to be associated with an increased
        risk of certain cancers and diseases.
      </p>
      <p>
        <journal-articles
          :nitrate="value.nitrate"
          :showHide="showHide"
          :showHideDefault="showHideDefault"
          :explore="explore"
          :extraArticles.sync="extraArticles"
        ></journal-articles>
      </p>
    </v-card-text>
    <div class="nitrate-footer pa-2">
      <p v-if="extraArticles && explore">
        Since water test results can vary over time, studies slightly above the
        nitrate test level of {{ this.value.nitrate }} mg/L are also displayed.
      </p>
      <p>
        Research on the latest science related to human health and well water is
        courtesy of and reprinted here with the permission of
        <!-- href="https://docstruro.org" -->
        <a href="https://docstruro.org" class="decoration-none" target="_blank"
          >Docs for Truro Safe Water</a
        >
      </p>
      <!-- <p>
        Scientific studies on health risks related to nitrates can also be found
        online at
        <a
          href="https://climateactiontruro.org/docs"
          target="blank"
          class="decoration-none"
        >
          climateactiontruro.org/docs</a
        >
      </p> -->
      <div v-if="showFooter()">
        <div class="nitrate-footer-1">
          For Your Health's Sake, Get Your Water Tested!
        </div>
        <div class="nitrate-footer-2">
          Call or Text 508-296-0007 and Request a Free Test
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import utils from '@/mixins/utils'
import { mapGetters } from 'vuex'
export default {
  components: {
    JournalArticles: () => import('@/components/Water/JournalArticles')
  },
  mixins: [utils],
  props: {
    value: {},
    showHideDefault: {
      type: Boolean,
      deafult: true
    },
    showHide: {
      type: Boolean,
      deafult: false
    },
    explore: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    extraArticles: false
  }),
  computed: {
    ...mapGetters(['latestParcel'])
  },
  methods: {
    isTruro() {
      if (this.latestParcel) {
        return this.checkParcelTown('Truro', this.latestParcel)
      } else {
        return false
      }
    },
    showFooter() {
      let show = true
      let last_test = null
      if ((last_test = this.$store.getters.waterTestHistory[0])) {
        last_test = new Date(last_test.testDate)
        const days = Math.ceil(
          Math.abs(new Date() - last_test) / (1000 * 60 * 60 * 24)
        )
        if (days < 365) {
          show = false
        }
      }
      if (!this.isTruro()) {
        show = false
      }
      return show
    }
  }
}
</script>

<style scoped>
@media (min-width: 600px) {
  .card-text {
    font-size: 20px;
    line-height: 35px;
  }
  .card-title {
    font-size: 26px !important;
  }
  .alert-icon i {
    font-size: 40px;
  }
}
@media (max-width: 600px) {
  .alert-icon i {
    font-size: 1.6rem;
  }
  .card-title {
    font-size: 1rem !important;
  }
}
.card-description {
  font-size: 18px;
}
.alert-icon {
  margin-top: -3px;
}
.nitrate-footer {
  background-color: #f5f5f5;
  padding-top: 10px;
  text-align: center;
  padding-bottom: 1rem;
  font-size: 13px;
  margin-top: -15px;
}
.nitrate-footer-1 {
  /* font-size: 1.5rem; */
  color: #2d6fba;
}
.nitrate-footer-2 {
  /* font-size: 1.5rem; */
  /* margin-top: -1.5rem; */
  color: #e93223;
}
</style>
